import type { AbilityBuilder } from "@casl/ability";
import { APPLICATION_STEP, SUBVENTION_PROJECT_STEP } from "~/constants/steps/steps";
import type { FlatAppAbility } from "~/types/permissions";
export const getCommitmentControllerPermissions = ({
  can
}: {
  can: AbilityBuilder<FlatAppAbility>["_addRule"];
}) => {
  can("seeBo", "dossiers");
  can(["create", "read"], "memos");
  can("read", "dossiers", "validationsData.decision", {
    step: {
      $nin: [APPLICATION_STEP.SUBMISSION, APPLICATION_STEP.ASSIGNMENT, APPLICATION_STEP.VERIFICATION, APPLICATION_STEP.MODIFICATION, APPLICATION_STEP.DECISION]
    }
  });

  // Subvention

  can("read", "dossiers", ["id", "application_form_answers"], {
    subvention_projects: {
      $elemMatch: {
        step: {
          $eq: SUBVENTION_PROJECT_STEP.COMMITMENT_CONTROLLER_VALIDATION
        }
      }
    }
  });
  can("read", "dossiers", "subvention_projects");
  can("update", "dossiers", "subvention_projects", {
    subvention_projects: {
      $elemMatch: {
        step: {
          $eq: SUBVENTION_PROJECT_STEP.COMMITMENT_CONTROLLER_VALIDATION
        }
      }
    }
  });
  can(["read", "create"], "subvention_projects", "validations", {
    step: {
      $eq: SUBVENTION_PROJECT_STEP.COMMITMENT_CONTROLLER_VALIDATION
    }
  });
  can("read", "subvention_projects", "step", {
    step: SUBVENTION_PROJECT_STEP.COMMITMENT_CONTROLLER_VALIDATION
  });

  // PDF DOCUMENTS
  can("read", "pdf_documents");
};