import type { AbilityBuilder } from "@casl/ability";
import { MINISTER_BUDGET_LEVEL } from "~/constants/application-flow";
import { APPLICATION_STEP, SUBVENTION_PROJECT_STEP } from "~/constants/steps/steps";
import type { FlatAppAbility } from "~/types/permissions";
export const getAuthorizingOfficerPermissions = ({
  can
}: {
  can: AbilityBuilder<FlatAppAbility>["_addRule"];
}) => {
  can(["create", "read"], "memos");
  can("seeBo", "dossiers");
  can("read", "dossiers", ["id", "application_form_answers"], {
    step: {
      $in: [APPLICATION_STEP.REDACTION, APPLICATION_STEP.ACTIVE, APPLICATION_STEP.REFUSAL_AUTHORIZING_OFFICER_VALIDATION, APPLICATION_STEP.REFUSAL_AUTHORIZING_OFFICER_SIGNATURE]
    }
  });
  can("read", "dossiers", "applicant.isActive", {
    "applicant.is_active": {
      $eq: false
    }
  });
  can("read", "dossiers", "organisation.isActive");
  can("read", "dossiers", "validationsData.decision", {
    step: {
      $nin: [APPLICATION_STEP.SUBMISSION, APPLICATION_STEP.ASSIGNMENT, APPLICATION_STEP.VERIFICATION, APPLICATION_STEP.MODIFICATION, APPLICATION_STEP.DECISION]
    }
  });
  can("read", "dossiers", "bad_news_letter_file", {
    step: {
      $eq: APPLICATION_STEP.REFUSAL_AUTHORIZING_OFFICER_VALIDATION
    }
  });
  can("read", "dossiers", "subvention_projects", {
    step: {
      $in: [APPLICATION_STEP.REDACTION, APPLICATION_STEP.ACTIVE, APPLICATION_STEP.CONTROL]
    }
  });
  can("create", "dossiers", "bad_news_letter_file_signature", {
    step: APPLICATION_STEP.REFUSAL_AUTHORIZING_OFFICER_SIGNATURE
  });
  can("update", "dossiers", "bad_news_letter", {
    step: {
      $eq: APPLICATION_STEP.REFUSAL_AUTHORIZING_OFFICER_VALIDATION
    }
  });
  can("create", "dossiers", "validations.bad_news_letter", {
    step: {
      $eq: APPLICATION_STEP.REFUSAL_AUTHORIZING_OFFICER_VALIDATION
    }
  });
  can("create", "dossiers", "bad_news_letter.signature", {
    step: {
      $eq: APPLICATION_STEP.REFUSAL_AUTHORIZING_OFFICER_SIGNATURE
    }
  });
  can("update", "dossiers", "subvention_projects", {
    subvention_projects: {
      $elemMatch: {
        step: {
          $in: [SUBVENTION_PROJECT_STEP.AUTHORIZING_OFFICER_VALIDATION, SUBVENTION_PROJECT_STEP.AUTHORIZING_OFFICER_SECOND_VALIDATION, SUBVENTION_PROJECT_STEP.AUTHORIZING_OFFICER_GRANT_DECREE_SIGNATURE, SUBVENTION_PROJECT_STEP.AUTHORIZING_OFFICER_GOOD_NEWS_LETTER_SIGNATURE, SUBVENTION_PROJECT_STEP.AUTHORIZING_OFFICER_AMENDMENT_DECREE_SIGNATURE]
        }
      }
    }
  });
  can("update", "subvention_projects", "all", {
    step: SUBVENTION_PROJECT_STEP.AUTHORIZING_OFFICER_VALIDATION
  });
  can("read", "subvention_projects", "step", {
    step: {
      $in: [SUBVENTION_PROJECT_STEP.AUTHORIZING_OFFICER_VALIDATION, SUBVENTION_PROJECT_STEP.AUTHORIZING_OFFICER_SECOND_VALIDATION]
    }
  });
  can("update", "subvention_projects", "step", {
    step: {
      $eq: SUBVENTION_PROJECT_STEP.AUTHORIZING_OFFICER_VALIDATION
    }
  });
  can("update", "subvention_projects", "step", {
    step: {
      $eq: SUBVENTION_PROJECT_STEP.AUTHORIZING_OFFICER_SECOND_VALIDATION
    },
    "budget_proposal_details.amount_granted": {
      $lt: MINISTER_BUDGET_LEVEL
    }
  });
  can("read", "subvention_projects", "validations");
  can("create", "subvention_projects", "validations", {
    step: {
      $in: [SUBVENTION_PROJECT_STEP.AUTHORIZING_OFFICER_VALIDATION, SUBVENTION_PROJECT_STEP.AUTHORIZING_OFFICER_SECOND_VALIDATION]
    }
  });
  can("create", "subvention_projects", "files", {
    step: {
      $in: [SUBVENTION_PROJECT_STEP.AUTHORIZING_OFFICER_GRANT_DECREE_SIGNATURE, SUBVENTION_PROJECT_STEP.AUTHORIZING_OFFICER_GOOD_NEWS_LETTER_SIGNATURE, SUBVENTION_PROJECT_STEP.AUTHORIZING_OFFICER_AMENDMENT_DECREE_SIGNATURE]
    }
  });
  can("create", "subvention_projects", "grant_decree_file", {
    step: {
      $eq: SUBVENTION_PROJECT_STEP.AUTHORIZING_OFFICER_GRANT_DECREE_SIGNATURE
    }
  });
  can("create", "subvention_projects", "good_news_letter_file", {
    step: {
      $eq: SUBVENTION_PROJECT_STEP.AUTHORIZING_OFFICER_GOOD_NEWS_LETTER_SIGNATURE
    }
  });
  can("create", "subvention_projects", "amendment_decree_file", {
    step: {
      $eq: SUBVENTION_PROJECT_STEP.AUTHORIZING_OFFICER_AMENDMENT_DECREE_SIGNATURE
    }
  });
  can("read", "dossiers", "liquidation", {
    step: {
      $in: [APPLICATION_STEP.ACTIVE, APPLICATION_STEP.CONTROL, APPLICATION_STEP.CONTROL_MODIFICATION, APPLICATION_STEP.CONTROL_OVERPAYMENT, APPLICATION_STEP.CONTROL_VERIFICATION, APPLICATION_STEP.ARCHIVED]
    }
  });

  // PDF DOCUMENTS
  can("read", "pdf_documents");
};