import {
  ROLES,
  WBI_DEPARTMENT_DEPENDENT_ROLES,
  WBI_HIERARCHY_INDENPENDENT_ROLES,
  WBI_SERVICE_DEPENDENT_ROLES,
  WBI_SUB_DEPARTMENT_DEPENDENT_ROLES,
} from "~/constants/users";
import type { DepartmentDependentRole, HierarchyIndependentRole, Role, ServiceDependentRole, SubDepartmentDependentRole, User } from "~/types/database";

const HIERARCHY_INDEPENDENT_ROLES: string[] = Object.values(WBI_HIERARCHY_INDENPENDENT_ROLES);
const SERVICE_DEPENDENT_ROLES: string[] = Object.values(WBI_SERVICE_DEPENDENT_ROLES);
const SUB_DEPARTMENT_DEPENDENT_ROLES: string[] = Object.values(WBI_SUB_DEPARTMENT_DEPENDENT_ROLES);
const DEPARTMENT_DEPENDENT_ROLES: string[] = Object.values(WBI_DEPARTMENT_DEPENDENT_ROLES);

const WbiUserRoles = Object.values(ROLES).filter((role) => role !== ROLES.APPLICANT);

// can't use lodash in middleware (build error) so instead of using lodash/uniq I redefined it
const uniq = <T>(array: T[]) => {
  return Array.from(new Set(array));
};

const checkSomeRoleForPredicate = (user: User | undefined, predicate: (role: Role) => boolean) => {
  if (!user) throw new Error("User is undefined");
  return user.roles.some(predicate);
};

export const hasUserRoles = (user?: User) => {
  return !!user ? user.roles.length > 0 : false;
};

export const getUserRoleTypes = (user?: User) => {
  return user ? uniq(user.roles.map((role) => role.type)) : [];
};

export const isWbiUser = (user?: User) => {
  return checkSomeRoleForPredicate(user, (role) => WbiUserRoles.includes(role.type));
};

export const isUserSuperAdmin = (user?: User) => {
  return checkSomeRoleForPredicate(user, (role) => role.type === ROLES.SUPER_ADMIN);
};

export const isUserAdmin = (user?: User) => {
  return checkSomeRoleForPredicate(user, (role) => role.type === ROLES.ADMIN);
};

export const isUserApplicant = (user?: User) => {
  return checkSomeRoleForPredicate(user, (role) => role.type === ROLES.APPLICANT);
};

export const isUserCommitmentController = (user?: User) => {
  return checkSomeRoleForPredicate(user, (role) => role.type === ROLES.COMMITMENT_CONTROLLER);
};

export const isUserFinancialInspector = (user?: User) => {
  return checkSomeRoleForPredicate(user, (role) => role.type === ROLES.FINANCIAL_INSPECTOR);
};

export const isUserAuthorizingOfficer = (user?: User) => {
  return checkSomeRoleForPredicate(user, (role) => role.type === ROLES.AUTHORIZING_OFFICER);
};

export const isUserAccountant = (user?: User) => {
  return checkSomeRoleForPredicate(user, (role) => role.type === ROLES.ACCOUNTANT);
};

export const isUserMinister = (user?: User) => {
  return checkSomeRoleForPredicate(user, (role) => role.type === ROLES.MINISTER);
};

export const isUserDepartmentHead = (user?: User, departmentId?: string) => {
  return checkSomeRoleForPredicate(user, (role) => role.type === ROLES.DEPARTMENT_HEAD && (!departmentId || role.department.id === departmentId));
};

export const isUserSubDepartmentHead = (user?: User, subDepartmentId?: string) => {
  return checkSomeRoleForPredicate(user, (role) => role.type === ROLES.SUB_DEPARTMENT_HEAD && (!subDepartmentId || role.sub_department.id === subDepartmentId));
};

export const isUserHandler = (user?: User, serviceId?: string) => {
  return checkSomeRoleForPredicate(user, (role) => role.type === ROLES.HANDLER && (!serviceId || role.service.id === serviceId));
};

export const isUserServiceHead = (user?: User, serviceId?: string) => {
  return checkSomeRoleForPredicate(user, (role) => role.type === ROLES.SERVICE_HEAD && (!serviceId || role.service.id === serviceId));
};

export const isRoleTypeHierarchyIndependent = (roleType: Role["type"]): roleType is HierarchyIndependentRole["type"] => {
  return HIERARCHY_INDEPENDENT_ROLES.includes(roleType);
};

export const isRoleTypeServiceDependent = (roleType: Role["type"]): roleType is ServiceDependentRole["type"] => {
  return SERVICE_DEPENDENT_ROLES.includes(roleType);
};

export const isRoleTypeSubDepartmentDependent = (roleType: Role["type"]): roleType is SubDepartmentDependentRole["type"] => {
  return SUB_DEPARTMENT_DEPENDENT_ROLES.includes(roleType);
};

export const isRoleTypeDepartmentDependent = (roleType: Role["type"]): roleType is DepartmentDependentRole["type"] => {
  return DEPARTMENT_DEPENDENT_ROLES.includes(roleType);
};

export const getServiceIdsAsRoleType = (user: User, roleType: ServiceDependentRole["type"]) => {
  const roles = user.roles.filter((role) => role.type === roleType) as ServiceDependentRole[];
  return roles.map((role) => role.service.id);
};

export const getSubDepartmentIdsAsRoleType = (user: User, roleType: SubDepartmentDependentRole["type"]) => {
  const roles = user.roles.filter((role) => role.type === roleType) as SubDepartmentDependentRole[];
  return roles.map((role) => role.sub_department.id);
};

export const getDepartmentIdsAsRoleType = (user: User, roleType: DepartmentDependentRole["type"]) => {
  const roles = user.roles.filter((role) => role.type === roleType) as DepartmentDependentRole[];
  return roles.map((role) => role.department.id);
};

export const filterServicesByRole = (services: { id: string; label: string }[], roleIds: string[]) => {
  return services.filter(({ id }) => roleIds.includes(id));
};
