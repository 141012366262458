import { CheckIcon, ExclamationCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import React from "react";
import { Toaster as SonnerToaster } from "sonner";
import { Typography } from "~/components/typography";
type ToastOptions = React.ComponentPropsWithoutRef<typeof SonnerToaster>["toastOptions"];
export const Toaster = (props: React.ComponentPropsWithoutRef<typeof SonnerToaster>) => {
  return <SonnerToaster visibleToasts={5} toastOptions={DEFAULT_TOAST_OPTIONS} duration={DEFAULT_TOAST_DURATION} {...props} data-sentry-element="SonnerToaster" data-sentry-component="Toaster" data-sentry-source-file="toast.tsx" />;
};
export const DEFAULT_TOAST_DURATION = 10_000; // 10 seconds
export const DEFAULT_TOAST_OPTIONS: ToastOptions = {
  className: clsx("group/toast box-border w-[var(--width)] shrink-0 overflow-hidden rounded-none bg-white"),
  style: {
    transition: "transform 400ms, opacity 400ms, height 0s, box-shadow 200ms !important"
  }
};
export const Toast = {
  Root: ({
    warning,
    ...props
  }: React.ComponentPropsWithoutRef<"div"> & {
    warning?: boolean;
  }) => {
    return <div className={clsx("flex shrink-0 items-center border px-6 py-4", warning ? "bg-error/20 border-error/80" : "bg-success/20 border-success/40")} {...props} />;
  },
  Content: (props: React.ComponentPropsWithoutRef<"div">) => {
    return <div className="flex w-full items-center h-12" {...props} />;
  },
  Body: (props: React.ComponentPropsWithoutRef<"div">) => {
    return <div className="flex min-w-0 flex-1 shrink-0 flex-col gap-4" {...props} />;
  },
  Title: ({
    children,
    ...props
  }: React.ComponentPropsWithoutRef<"h1">) => {
    return <Typography.body data-testid="toast-title" className="align-middle line-clamp-2" fontWeight="600" textColor="text-black" {...props}>
        {children}
      </Typography.body>;
  },
  Dismiss: (props: React.ComponentPropsWithoutRef<"button">) => {
    return <button onClick={props.onClick} className="pl-2 text-black" {...props}>
        <XMarkIcon className="h-5 w-5" />
        <Typography.body className="sr-only">Fermer</Typography.body>
      </button>;
  },
  Progress: ({
    duration = DEFAULT_TOAST_DURATION,
    className,
    ...props
  }: React.ComponentPropsWithoutRef<"div"> & {
    duration?: number;
  }) => {
    if (isFinite(duration)) {
      return <div style={({
        "--animation-time": `${duration}ms`
      } as React.CSSProperties)} className={clsx("absolute inset-x-0 bottom-0 h-1 overflow-hidden bg-neutral-300", className)} {...props}>
          <div className="group-data-[expanded=true]/toast:animate-pause animate-progress-bar group-data-[expanded=false]/toast:animate-play h-full bg-neutral-800 transition-all" />
        </div>;
    }
    return null;
  },
  CheckIcon: () => {
    return <span className="bg-success mr-6 flex items-center justify-center self-center rounded-full w-[30px] h-[30px]">
        <CheckIcon className="h-4 w-4 shrink-0 text-white" />
      </span>;
  },
  WarningIcon: () => {
    return <span className="bg-error mr-6 flex items-center justify-center self-center rounded-full p-3">
        <ExclamationCircleIcon className="h-5 w-5 shrink-0 text-white" />
      </span>;
  }
};
export type ToastProps = {
  duration?: number;
  onDismiss: () => void;
};

// Not used yet, but in the Figma design. It is a regression in terms of UX compared to the modal so let's wait until the figma is updated with the toasts.
export const DraftSavedToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="DraftSavedToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Progression sauvegardée en brouillon</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const ChangeProjectOwnerToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="ChangeProjectOwnerToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Modifications du porteur de projet faites</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const ModificationAskedToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="ModificationAskedToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Modifications demandées</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const CompletenessConfirmedToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="CompletenessConfirmedToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Complétude confirmée</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const ApplicationArchivedToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="ApplicationArchivedToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Dossier archivé</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const ApplicationAssignedToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="ApplicationAssignedToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Dossier attribué</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const DispositifCreatedToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="DispositifCreatedToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Dispositif créé</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const DispositifUpdatedToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="DispositifUpdatedToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Dispositif modifié</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const DispositifDuplicateToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="DispositifDuplicateToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Dispositif dupliqué</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const ApplicationUpdatedTitleToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="ApplicationUpdatedTitleToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Libellé enregistré</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const ApplicationSubmittedToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="ApplicationSubmittedToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Dossier envoyé</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const ChangeServiceToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="ChangeServiceToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Changement de service appliqué</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const SubventionProjectSubmittedToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="SubventionProjectSubmittedToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Projet de subvention envoyé</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const AmendingProjectSubmittedToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="AmendingProjectSubmittedToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Projet modificatif envoyé</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const OrganisationCreatedToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="OrganisationCreatedToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Organisme ajouté</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const OrganisationValidatedToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="OrganisationValidatedToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Organisme validé</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const OrganisationRequestSentToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="OrganisationRequestSentToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Demande d&apos;ajout d&apos;organisme envoyé</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const OrganisationUpdateToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="OrganisationUpdateToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Changement sauvegardé</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const BeneficiaryDeleteToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="BeneficiaryDeleteToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Compte supprimé!</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const OrganisationAddBeneficiary = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="OrganisationAddBeneficiary" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Numéro de compte ajouté</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const ResendOrganisationToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="ResendOrganisationToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Demande d&apos;ajout d&apos;organisme renvoyé</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const OrganisationDeletedToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="OrganisationDeletedToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Organisme supprimé</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const AddNewMemberToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="AddNewMemberToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Membre ajouté</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const RemoveMemberToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="RemoveMemberToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Membre supprimé de l&apos;organisme</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const DisabledMemberToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="DisabledMemberToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Utilisateur désactivé</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const EnabledMemberToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="EnabledMemberToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Utilisateur activé</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const DecisionSubmittedToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="DecisionSubmittedToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Décision rendue</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const DeletedGroupToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="DeletedGroupToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Sous-groupe supprimé</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const ContentUpdatedToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="ContentUpdatedToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Contenu mis à jour</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const ErrorToast = ({
  title = "Une erreur est survenue",
  onDismiss
}: ToastProps & {
  title?: string;
}) => <Toast.Root warning data-sentry-element="unknown" data-sentry-component="ErrorToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.WarningIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">{title}</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const ReferenceDispositifUpdatedToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="ReferenceDispositifUpdatedToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Dispositif de référence sauvegardé</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const ServiceCreatedToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="ServiceCreatedToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Service ajouté</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const SubDepartmentCreatedToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="SubDepartmentCreatedToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Sous-département ajouté</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const DepartmentCreatedToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="DepartmentCreatedToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Département ajouté</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const ServiceDisabledToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="ServiceDisabledToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Service désactivé</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const SubDepartmentDisabledToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="SubDepartmentDisabledToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Sous-département désactivé</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const DepartmentDisabledToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="DepartmentDisabledToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Département désactivé</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const ServiceEnabledToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="ServiceEnabledToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Service activé</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const SubDepartmentEnabledToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="SubDepartmentEnabledToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Sous-département activé</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const DepartmentEnabledToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="DepartmentEnabledToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Département activé</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const ModificationsMadeToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="ModificationsMadeToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Modifications apportées</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const NoticeSentToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="NoticeSentToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Avis transmis</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const BadNewsLetterSignedToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="BadNewsLetterSignedToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Lettre de mauvaise nouvelle signée</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const GrantDecretSignedToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="GrantDecretSignedToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Arrêté de subvention signé</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const PaymentConfirmedToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="PaymentConfirmedToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Paiement confirmé</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const ControlStartedToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="ControlStartedToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Processus de contrôle lancé</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;
export const OverPaymentStartedToast = ({
  onDismiss
}: ToastProps) => <Toast.Root data-sentry-element="unknown" data-sentry-component="OverPaymentStartedToast" data-sentry-source-file="toast.tsx">
    <Toast.Content data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
      <Toast.CheckIcon data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
      <Toast.Body data-sentry-element="unknown" data-sentry-source-file="toast.tsx">
        <Toast.Title data-sentry-element="unknown" data-sentry-source-file="toast.tsx">Processus de trop perçu lancé</Toast.Title>
      </Toast.Body>
      <Toast.Dismiss onClick={onDismiss} data-sentry-element="unknown" data-sentry-source-file="toast.tsx" />
    </Toast.Content>
  </Toast.Root>;