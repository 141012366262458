import type { AbilityBuilder } from "@casl/ability";
import { VALUES_LIST_TABS } from "~/constants/list-values";
import { APPLICATION_STEP, LIQUIDATION_STEP, ORGANISATION_STEP, SUBVENTION_PROJECT_STEP } from "~/constants/steps/steps";
import type { User } from "~/types/database";
import type { FlatAppAbility } from "~/types/permissions";
import { getUserRoleTypes } from "~/utils/user-role";
export const getSuperAdminPermissions = ({
  can,
  superAdmin
}: {
  can: AbilityBuilder<FlatAppAbility>["_addRule"];
  superAdmin: User;
}) => {
  const userRoleTypes = getUserRoleTypes(superAdmin);

  //Application editing/view
  can(["update", "read"], "dossiers", {
    created_by: superAdmin.id,
    step: {
      $in: [APPLICATION_STEP.SUBMISSION]
    }
  });
  can("seeBo", "dossiers");
  can("create", "dossiers", "Bo");
  can("read", "dossiers", "applicant.isActive", {
    "applicant.is_active": {
      $eq: false
    }
  });
  can("read", "dossiers", "organisation.isActive");
  can("read", "dossiers", "validationsData.decision", {
    step: {
      $nin: [APPLICATION_STEP.SUBMISSION, APPLICATION_STEP.ASSIGNMENT, APPLICATION_STEP.VERIFICATION, APPLICATION_STEP.MODIFICATION, APPLICATION_STEP.DECISION]
    }
  });
  can(["update"], "dossiers", {
    created_by: superAdmin.id,
    step: {
      $in: [APPLICATION_STEP.MODIFICATION]
    }
  });
  can("read", "dossiers", "application_form_answers", {
    step: {
      $nin: [APPLICATION_STEP.SUBMISSION]
    }
  });

  // Dossier
  can("update", "dossiers", "handler_title");
  can("update", "dossiers", "service_id");
  can(["create", "read"], "dossiers");
  can(["create", "read"], "memos");
  can("update", "dossiers", "handler_id");
  can("create", "dossiers", "validationsData.completed", {
    step: APPLICATION_STEP.VERIFICATION
  });
  can("read", "dossiers", "validationsData", {
    step: APPLICATION_STEP.DECISION
  });
  can("update", "dossiers", "validationsData", {
    step: APPLICATION_STEP.REDACTION
  });
  can("create", "dossiers", "bad_news_letter_file", {
    step: APPLICATION_STEP.REFUSAL_BAD_NEWS_LETTER_WRITING
  });
  can("create", "dossiers", "validations.bad_news_letter", {
    step: {
      $in: [APPLICATION_STEP.REFUSAL_SERVICE_HEAD_VALIDATION, APPLICATION_STEP.REFUSAL_AUTHORIZING_OFFICER_VALIDATION]
    }
  });
  can("create", "dossiers", "bad_news_letter.signature", {
    step: APPLICATION_STEP.REFUSAL_AUTHORIZING_OFFICER_SIGNATURE
  });
  can("update", "dossiers", "bad_news_letter_file", {
    step: APPLICATION_STEP.REFUSAL_MODIFICATION
  });
  can("update", "dossiers", "closed_early", {
    step: APPLICATION_STEP.ACTIVE
  });
  can("create", "dossiers", "subvention_projects", {
    step: {
      $in: [APPLICATION_STEP.REDACTION, APPLICATION_STEP.ACTIVE]
    }
  });

  // Subvention
  can("create", "subvention_projects", {
    step: SUBVENTION_PROJECT_STEP.CREATION
  });
  can("update", "subvention_projects", {
    step: {
      $in: [SUBVENTION_PROJECT_STEP.MODIFICATION]
    }
  });
  can("delete", "subvention_projects", {
    step: SUBVENTION_PROJECT_STEP.CREATION
  });
  can("read", "subvention_projects", {
    step: {
      $in: [SUBVENTION_PROJECT_STEP.SERVICE_HEAD_VALIDATION, SUBVENTION_PROJECT_STEP.SUB_DEPARTMENT_HEAD_VALIDATION, SUBVENTION_PROJECT_STEP.DEPARTMENT_HEAD_VALIDATION, SUBVENTION_PROJECT_STEP.COMMITMENT_CONTROLLER_VALIDATION, SUBVENTION_PROJECT_STEP.AUTHORIZING_OFFICER_VALIDATION, SUBVENTION_PROJECT_STEP.AUTHORIZING_OFFICER_SECOND_VALIDATION, SUBVENTION_PROJECT_STEP.MINISTER_VALIDATION]
    }
  });
  can("update", "subvention_projects", {
    step: {
      $in: [SUBVENTION_PROJECT_STEP.SERVICE_HEAD_VALIDATION, SUBVENTION_PROJECT_STEP.SUB_DEPARTMENT_HEAD_VALIDATION, SUBVENTION_PROJECT_STEP.DEPARTMENT_HEAD_VALIDATION, SUBVENTION_PROJECT_STEP.COMMITMENT_CONTROLLER_VALIDATION, SUBVENTION_PROJECT_STEP.AUTHORIZING_OFFICER_VALIDATION, SUBVENTION_PROJECT_STEP.MINISTER_VALIDATION]
    }
  });
  can("read", "subvention_projects", "step", {
    step: SUBVENTION_PROJECT_STEP.FINANCIAL_INSPECTOR_OPINION
  });
  can("create", "subvention_projects", "files", {
    step: {
      $in: [SUBVENTION_PROJECT_STEP.AUTHORIZING_OFFICER_GRANT_DECREE_SIGNATURE, SUBVENTION_PROJECT_STEP.MINISTER_GRANT_DECREE_SIGNATURE, SUBVENTION_PROJECT_STEP.AUTHORIZING_OFFICER_GOOD_NEWS_LETTER_SIGNATURE, SUBVENTION_PROJECT_STEP.AUTHORIZING_OFFICER_AMENDMENT_DECREE_SIGNATURE, SUBVENTION_PROJECT_STEP.MINISTER_AMENDMENT_DECREE_SIGNATURE]
    }
  });
  can("create", "subvention_projects", "grant_decree_file", {
    step: {
      $in: [SUBVENTION_PROJECT_STEP.AUTHORIZING_OFFICER_GRANT_DECREE_SIGNATURE, SUBVENTION_PROJECT_STEP.MINISTER_GRANT_DECREE_SIGNATURE]
    }
  });
  can("create", "subvention_projects", "good_news_letter_file", {
    step: {
      $in: [SUBVENTION_PROJECT_STEP.AUTHORIZING_OFFICER_GOOD_NEWS_LETTER_SIGNATURE]
    }
  });
  can("create", "subvention_projects", "amendment_decree_file", {
    step: {
      $in: [SUBVENTION_PROJECT_STEP.AUTHORIZING_OFFICER_AMENDMENT_DECREE_SIGNATURE, SUBVENTION_PROJECT_STEP.MINISTER_AMENDMENT_DECREE_SIGNATURE]
    }
  });
  can("update", "subvention_projects", "all", {
    step: {
      $in: [SUBVENTION_PROJECT_STEP.CREATION, SUBVENTION_PROJECT_STEP.MODIFICATION]
    }
  });

  // Liquidation
  can("create", "dossiers", {
    step: {
      $in: [APPLICATION_STEP.ACTIVE]
    }
  });
  can("create", "liquidations", {
    step: {
      $in: [LIQUIDATION_STEP.SUBMISSION]
    }
  });
  can("update", "liquidations", {
    step: {
      $in: [LIQUIDATION_STEP.MODIFICATION, LIQUIDATION_STEP.VERIFICATION]
    }
  });
  can("update", "liquidations", {
    step: {
      $in: [LIQUIDATION_STEP.SUBMISSION, LIQUIDATION_STEP.MODIFICATION, LIQUIDATION_STEP.VERIFICATION, LIQUIDATION_STEP.SERVICE_HEAD_VALIDATION]
    }
  });
  can("read", "dossiers", "liquidation", {
    step: {
      $in: [APPLICATION_STEP.ACTIVE, APPLICATION_STEP.CLOSURE, APPLICATION_STEP.CONTROL, APPLICATION_STEP.CONTROL_VERIFICATION, APPLICATION_STEP.CONTROL_MODIFICATION, APPLICATION_STEP.CONTROL_OVERPAYMENT, APPLICATION_STEP.ARCHIVED]
    }
  });

  // LIQUIDATIONS
  can("read", "liquidations", {
    step: {
      $in: [LIQUIDATION_STEP.MODIFICATION]
    }
  });
  can("update", "liquidations", {
    step: {
      $in: [LIQUIDATION_STEP.VERIFICATION]
    }
  });
  can("update", "liquidations", "amount", {
    step: {
      $in: [LIQUIDATION_STEP.PAYMENT]
    }
  });
  can("create", "liquidations", "validations", {
    step: {
      $in: [LIQUIDATION_STEP.SERVICE_HEAD_VALIDATION, LIQUIDATION_STEP.ACCOUNTANT_VALIDATION]
    }
  });
  can("delete", "liquidations", {
    created_by_role: {
      $in: userRoleTypes
    },
    step: LIQUIDATION_STEP.SUBMISSION
  });
  can("update", "liquidations", "all", {
    created_by_role: {
      $in: userRoleTypes
    },
    step: LIQUIDATION_STEP.SUBMISSION
  });

  // Control
  can("read", "dossiers", "control_requested", {
    step: {
      $in: [APPLICATION_STEP.CONTROL, APPLICATION_STEP.CONTROL_VERIFICATION, APPLICATION_STEP.CONTROL_MODIFICATION, APPLICATION_STEP.CONTROL_OVERPAYMENT]
    }
  });
  can("read", "dossiers", "control_requested", {
    controlled: true,
    step: APPLICATION_STEP.ARCHIVED
  });
  can("read", "dossiers", "control_requested", {
    closed_early: true,
    has_overpayment: true,
    step: APPLICATION_STEP.ARCHIVED
  });
  can("create", "dossiers", "controlled", {
    step: APPLICATION_STEP.CLOSURE
  });
  can("read", "dossiers", "closureCard", {
    step: APPLICATION_STEP.CLOSURE
  });
  can("read", "dossiers", "closureCard", {
    step: APPLICATION_STEP.CLOSURE
  });
  can("read", "dossiers", "control_requested.modification", {
    step: APPLICATION_STEP.CONTROL_MODIFICATION
  });
  can("create", "dossiers", "validations.control_files", {
    step: APPLICATION_STEP.CONTROL_VERIFICATION
  });
  can("create", "dossiers", "control_files", {
    step: {
      $in: [APPLICATION_STEP.CONTROL, APPLICATION_STEP.CONTROL_MODIFICATION]
    }
  });
  can("read", "dossiers", "control_files", {
    closed_early: false,
    step: {
      $in: [APPLICATION_STEP.CONTROL_VERIFICATION, APPLICATION_STEP.CONTROL_OVERPAYMENT]
    }
  });
  can("read", "dossiers", "control_files", {
    closed_early: false,
    controlled: true,
    step: APPLICATION_STEP.ARCHIVED
  });
  can("read", "dossiers", "step.archived", {
    step: APPLICATION_STEP.CLOSURE
  });

  // Reference dispositifs
  can("update", "reference_dispositifs");

  // DISPOSITIFS
  can(["create", "read"], "dispositifs");
  can("update", "dispositifs", "id", {
    status: "draft"
  });
  can("update", "dispositifs", "status", {
    status: "published"
  });
  can("delete", "dispositifs", {
    status: "draft"
  });

  // SERVICES
  can(["create", "read", "update", "delete"], "services");

  // USERS
  can(["create", "read", "update", "delete"], "users");

  // SUB DEPARTMENTS
  can(["update", "read"], "sub_departments");

  // DEPARTMENTS
  can(["update", "read"], "departments");

  // PDF
  can(["read", "update"], "pdf_templates");
  can("update", "pdf_configs", "all");
  can(["read", "update"], "pdf_documents");
  can("delete", "files");
  can("update", "contact_info");
  can(["read", "create", "update"], "values_lists");
  can("delete", "values_lists", {
    for: VALUES_LIST_TABS.DISPOSITIF
  });

  // Organisations
  can("read", "organisations", "id");
  can("update", "organisations", "id", {
    step: {
      $eq: ORGANISATION_STEP.APPROVED
    }
  });
  can("update", "organisations", "step", {
    step: {
      $eq: ORGANISATION_STEP.MODIFICATION
    }
  });
  can("delete", "organisations", "id", {
    step: ORGANISATION_STEP.MODIFICATION
  });
  can("update", "organisations", "is_active", {
    step: {
      $eq: ORGANISATION_STEP.APPROVED
    }
  });

  // Organisation memberships
  can("read", "organisations", "memberships");
  can("create", "organisations", "memberships", {
    step: {
      $eq: ORGANISATION_STEP.APPROVED
    }
  });
  can("delete", "organisations", "memberships", {
    step: {
      $eq: ORGANISATION_STEP.APPROVED
    }
  });
  can("update", "organisations", "memberships.role", {
    step: {
      $eq: ORGANISATION_STEP.APPROVED
    }
  });

  // Organisation memberships authorisation files
  can("read", "organisations", "memberships.authorisation_files", {
    step: {
      $ne: ORGANISATION_STEP.APPROVED
    }
  });
  can("update", "organisations", "memberships.authorisation_files", {
    step: {
      $eq: ORGANISATION_STEP.MODIFICATION
    }
  });

  // Organisation validations
  can("read", "organisations", "validations", {
    step: ORGANISATION_STEP.MODIFICATION
  });
  can("create", "organisations", "validations", {
    step: {
      $nin: [ORGANISATION_STEP.MODIFICATION, ORGANISATION_STEP.APPROVED]
    }
  });

  // Organisation beneficiaries
  can("create", "organisations", "beneficiary_authorisations.beneficiaries", {
    step: {
      $in: [ORGANISATION_STEP.APPROVED]
    }
  });
  can("delete", "organisations", "beneficiary_authorisations.beneficiaries", {
    step: {
      $in: [ORGANISATION_STEP.APPROVED]
    }
  });
};