import type { AbilityClass } from "@casl/ability";
import { AbilityBuilder } from "@casl/ability";
import { createContextualCan } from "@casl/react";
import getConfig from "next/config";
import type { ReactNode } from "react";
import { createContext, useContext } from "react";
import { APPLICATION_STEP, SUBVENTION_PROJECT_STEP } from "~/constants/steps/steps";
import type { User } from "~/types/database";
import { AppAbility, type CanProps, type CanPropsBis, type FlatAppAbility } from "~/types/permissions";
import { getAccountantPermissions } from "~/utils/permissions/accountant";
import { getAdminPermissions } from "~/utils/permissions/admin";
import { getApplicantPermissions } from "~/utils/permissions/applicant";
import { getAuthorizingOfficerPermissions } from "~/utils/permissions/authorizing-officer";
import { getCommitmentControllerPermissions } from "~/utils/permissions/commitment-controller";
import { getDepartmentHeadPermissions } from "~/utils/permissions/department-head";
import { getFinancialInspectorPermissions } from "~/utils/permissions/financial-inspector";
import { getHandlerPermissions } from "~/utils/permissions/handler";
import { getMinisterPermissions } from "~/utils/permissions/minister";
import { getServiceHeadPermissions } from "~/utils/permissions/service-head";
import { getSubDepartmentHeadPermissions } from "~/utils/permissions/sub-department-head";
import { getSuperAdminPermissions } from "~/utils/permissions/super-admin";
import { hasUserRoles, isUserAccountant, isUserAdmin, isUserApplicant, isUserAuthorizingOfficer, isUserCommitmentController, isUserDepartmentHead, isUserFinancialInspector, isUserHandler, isUserMinister, isUserServiceHead, isUserSubDepartmentHead, isUserSuperAdmin, isWbiUser } from "~/utils/user-role";
const {
  publicRuntimeConfig
} = getConfig();
export default function defineRulesFor(user?: User) {
  // tldr: dont'use cannot

  // longer version:
  // Dealing with inverted rules (cannot) in casl can be a pain (see https://casl.js.org/v6/en/guide/define-rules#inverted-rules-order)
  // Documentation is not very clear on the behavior when a rule and inverted rule are both defined for the same action and subject
  // It gets even more complex when dealing with users that have mutiple roles, since a can for one role could cancel a cannot for another role...
  // Additionally, casl recommends to use "cannot" as little as possible (https://casl.js.org/v6/en/guide/define-rules#best-practice)
  const {
    can,
    rules
  } = new AbilityBuilder<FlatAppAbility>((AppAbility as AbilityClass<FlatAppAbility>));
  if (!user || !hasUserRoles(user)) {
    return [];
  }
  if (publicRuntimeConfig.APP_ENV !== "production") {
    can("read", "Experiment");
  }
  can("delete", "dossiers", {
    created_by: user.id,
    step: APPLICATION_STEP.SUBMISSION
  });
  can("read", "dossiers", "handler_id");
  can("read", "dossiers", "applicant_id");
  can("read", "users", "id", {
    id: user.id
  });
  if (isWbiUser(user)) {
    can("read", "dossiers", "subvention_projects.validation");
    can("read", "dossiers", "control_requested.negatif");
    can("read", "application_history");
    can("read", "dossiers", "validationsData.summary", {
      step: {
        $in: [APPLICATION_STEP.REFUSAL_BAD_NEWS_LETTER_WRITING, APPLICATION_STEP.REFUSAL_MODIFICATION, APPLICATION_STEP.REFUSAL_SERVICE_HEAD_VALIDATION, APPLICATION_STEP.REFUSAL_AUTHORIZING_OFFICER_VALIDATION, APPLICATION_STEP.REFUSAL_AUTHORIZING_OFFICER_SIGNATURE, APPLICATION_STEP.ARCHIVED]
      }
    });
    can("read", "dossiers", "control_requested", {
      control_requested: {
        $eq: true
      },
      step: {
        $in: [APPLICATION_STEP.CONTROL, APPLICATION_STEP.CONTROL_MODIFICATION, APPLICATION_STEP.CONTROL_OVERPAYMENT, APPLICATION_STEP.CONTROL_VERIFICATION, APPLICATION_STEP.ARCHIVED]
      }
    });
    can("read", "dossiers", "control_requested.badge", {
      control_requested: {
        $eq: true
      },
      step: {
        $eq: APPLICATION_STEP.ACTIVE
      }
    });
    can("read", "dossiers", "subvention_projects.budget_proposal_details", {
      step: {
        $in: [APPLICATION_STEP.REDACTION, APPLICATION_STEP.ACTIVE, APPLICATION_STEP.CLOSURE, APPLICATION_STEP.CONTROL, APPLICATION_STEP.CONTROL_MODIFICATION, APPLICATION_STEP.CONTROL_OVERPAYMENT, APPLICATION_STEP.CONTROL_VERIFICATION, APPLICATION_STEP.ARCHIVED]
      }
    });
    can("read", "organisations", "id");
    can("read", "users", "id");
    can("read", "subvention_projects", "validations", {
      step: {
        $ne: SUBVENTION_PROJECT_STEP.CREATION
      }
    });
    can("read", "liquidations", "validations");
    if (isUserAccountant(user)) getAccountantPermissions({
      can
    });
    if (isUserAdmin(user)) getAdminPermissions({
      can
    });
    if (isUserAuthorizingOfficer(user)) getAuthorizingOfficerPermissions({
      can
    });
    if (isUserCommitmentController(user)) getCommitmentControllerPermissions({
      can
    });
    if (isUserDepartmentHead(user)) getDepartmentHeadPermissions({
      can,
      departmentHead: user
    });
    if (isUserFinancialInspector(user)) getFinancialInspectorPermissions({
      can
    });
    if (isUserHandler(user)) getHandlerPermissions({
      can,
      handler: user
    });
    if (isUserMinister(user)) getMinisterPermissions({
      can
    });
    if (isUserServiceHead(user)) getServiceHeadPermissions({
      can,
      serviceHead: user
    });
    if (isUserSubDepartmentHead(user)) getSubDepartmentHeadPermissions({
      can,
      subDepartmentHead: user
    });
    if (isUserSuperAdmin(user)) getSuperAdminPermissions({
      can,
      superAdmin: user
    });
  } else if (isUserApplicant(user)) {
    getApplicantPermissions({
      can,
      applicant: user
    });
  }
  return rules;
}
export function buildAbilityFor(user?: User): AppAbility {
  return new AppAbility(defineRulesFor(user), {
    detectSubjectType: object => typeof object === "string" ? object : object.__typename
  });
}
export const AbilityContext = createContext(({} as AppAbility));
export const useAbilityContext = () => useContext(AbilityContext);
export const Can = createContextualCan(AbilityContext.Consumer);
export const CanSkipNull = ({
  children,
  ...props
}: Omit<CanPropsBis, "this"> & {
  this: CanPropsBis["this"] | null;
  children: ReactNode;
}) => {
  const canThis = props.this;
  if (canThis !== null) {
    return <Can {...props} this={canThis}>
        {children}
      </Can>;
  }
  return <>{children}</>;
};
export const OptionalCan = ({
  children,
  canProps
}: {
  canProps?: CanProps;
  children: React.ReactNode;
}) => {
  if (!canProps) return <>{children}</>;
  return <Can {...canProps} data-sentry-element="Can" data-sentry-component="OptionalCan" data-sentry-source-file="permissions.tsx">{children}</Can>;
};

// TODO: this should be replaced by the OptionalCan FC
export const OptionalCanBis = ({
  children,
  canProps
}: {
  canProps?: CanPropsBis;
  children: React.ReactNode;
}) => {
  if (!canProps) return <>{children}</>;
  return <Can {...canProps} data-sentry-element="Can" data-sentry-component="OptionalCanBis" data-sentry-source-file="permissions.tsx">{children}</Can>;
};